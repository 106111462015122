* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --rotate-y: 0;
  --rotate-x: 0;
  --ratio: calc(1vw + 1vh);
  --transition: 1.5s cubic-bezier(0.05, 0.5, 0, 1);
  --logo-size: calc(var(--ratio) * 12.8);
}

body {
  color: #fff;
  background-color: #000;
  font-family: 'Marion', serif;
  overflow-y: auto;
}

@font-face {
  font-family: 'Marion', serif;
  src: url('./fonts/Marion Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


// 3D parallax effect
.parallax {
  transform-style: preserve-3d;
  transform: rotateX(var(--rotate-x)) rotateY(var(--rotate-y));
  transition: transform var(--transition);
  will-change: transform;
}

.layers {
  perspective: 1000px;
  overflow: hidden;
}

.layers__container {
  height: 100vh;
  min-height: 500px;
}

.layers__item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5vw;
  bottom: -5vw;
  left: -5vw;
  right: -5vw;
  background-size: cover;
  background-position: center;
}

.layer-background {
  transform: translateZ(-55px) scale(1.06);
  background-image: url(../images/OoklapeetsDesk.png);
}

.layer-1-ast {
  transform: translateZ(80px) scale(0.88);
  background-image: url(../images/desk1.png);
}

.layer-2-main {
  transform: translateZ(180px) scale(0.8);
}

.layer-3-rain {
  transform: translateZ(190px) scale(0.9);
  transform: none;
}

.layer-4-leaves {
  transform: translateZ(300px) scale(0.9);
  background-image: url(../images/rainbow.png);
  opacity: 0.3;
}

.layer-5-glow {
  transform: translateZ(380px);
  background-image: url(../images/layer-5-glow.png);
}

// makes these layers transparent to mouse events
.layer-3-rain,
.layer-4-leaves,
.layer-5-glow {
  pointer-events: none;
}

.logo {
  // position: absolute;
  // left: calc(51% - calc(var(--logo-size) / 2));
  // top: calc(var(--ratio) * 5.8);
  margin-top: 40px;
  width: var(--logo-size);
  height: var(--logo-size);
  background-image: url(../images/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

// .main-content {
//   margin-top: calc(var(--ratio) * 5.5);
//   font-size: calc(var(--ratio) * 2.9);
//   letter-spacing: calc(var(--ratio) * -0.15);
//   line-height: 1.35em;
//   text-align: center;
//   text-transform: uppercase;

//   .subtitle {
//     font-size: calc(var(--ratio) * 1.2);
//     font-style: italic;
//     line-height: 3;
//     letter-spacing: normal;
//     text-transform: none;
//   }
// }




.main-content {
  display: flex;          /* Use flexbox layout */
  height: 100vh;          /* Full viewport height */
  width: 100vw;           /* Full viewport width */
  margin: 0;              /* Remove default margin */
  justify-content: space-between; /* Space out columns */
  padding: 0 10px;
}

.content-div, .upcoming-div{
  padding-bottom: 10px;
  font-size: medium;
  padding-left: 20%;
  justify-content: flex-start;
}
.content-div2{
  padding-bottom: 10px;
  font-size: medium;
  padding-left: 0%;
  justify-content: flex-start;
}
span.span-content{
  font-weight: bold;
  padding-bottom: 25px;
  padding-top: 25px;
}
.left{flex-direction: column;}
.left{
  flex: 1;                /* Each flex item takes equal width */
  display: flex;          /* Also make each item a flex container */
  justify-content: center;/* Center content horizontally */
  // align-items: center;    /* Center content vertically */       
}
.right, .center{
  flex: 1;                /* Each flex item takes equal width */
  display: flex;          /* Also make each item a flex container */
  justify-content: center;/* Center content horizontally */
  align-items: center;    /* Center content vertically */       
}
#video-container > iframe{
  border: none;
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Aligns one item at the top and one at the bottom */
  align-items: center; /* Centers the items horizontally */
  height: 100vh; /* Takes full height of the parent, which is .main-content */
}

 .logo{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
 }
 .socials{
  display: flex;
  // justify-content: flex-end;
  // align-items: center;
  // padding-top: 20px;
 }

.upcoming-div{
  padding-top: 50px;
}
span.upcoming{
  font-weight: bold;
  font-style: italic;
}
a{
  // font-weight: bold;
  font-style: italic;
  color: #eca30e;
  text-decoration: none;
}
a:hover{
  transition: all 0.3s;
  color: #89d65b;
  
}

.twitter{
  font-size: x-small;
  text-align: center;
}


.btn {
  font-weight: 100;
  padding: 10px;
  margin: 10px;
  border-radius: 50%;
  border: rgba(255,255,255,.4) 2px solid;
  color: #fff;
  background-color: rgba(0,0,0,0);
  text-transform: uppercase;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: rgb(255 255 255 / 0.2);
  }
}
.svg-inline--fa {
  display: var(--fa-display,inline-block);
  height: 2em;
  overflow: visible;
  vertical-align: -0.125em;
  min-width: 30px;
}
.btn-github {
  margin-top: calc(var(--ratio) * 2.5);
}

.video-container{position: relative; width: 100%; min-height: 350px; right: 20px;}

.mobile{display: none;}
/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .video-container{right: 0;}
    .mobile{display: flex;}
    .desktop{display: none;}
    .parallax, .layers, .layers__container, .layers__item {
      transform: none;
      // position: relative;
      height: auto;
      overflow: visible;
    
  }
  .layers__container{min-height: 1000px;}

  .logo{height: 100%; width: 90%;}
  .main-content {
    flex-direction: column; /* Stack flex items vertically */
    height: 100%;
    overflow-y: auto;
  }

  .btn {padding: 7px; margin: 7px;}

  .left, .center, .right {
    width: 100%; /* Each child takes full width */
    height: 100%;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    padding: 0px 0; /* Add some vertical padding for spacing */
  }
  .upcoming-div{padding-top: 5px;}
  .content-div, .upcoming-div {
    padding-left: 0px;
    margin: 0 10%;
}
  .center {
    order: -1; /* Places center on top */
   
  }

  .left {
    order: 1; /* Places left in the middle */
    align-items: flex-start;
    background: #000;
    padding: 18px 0px;
  }

  .right {
    order: 0; /* Places right on the bottom */
    top: -25px;
    position: relative;
  }
  .span-content{padding: 0px;}
  .spacer{height: 20px;}
  
}